<template>
  <div class="summary-delivery">
    <div v-if="undefined !== product.getWarrantyValue()" class="warranty-block">
      <h4 class="warranty-block__title">{{ _T("@Warranty") }}</h4>
      <div class="warranty-block__content">
        <div class="warranty-block__icon-w">
          <img
            src="~~/assets/icons/warranty-shield.svg"
            alt="warranty period"
            class="warranty-block__icon"
          />
          <span class="warranty-block__icon-text">
            {{ product.getWarrantyValue() }}
          </span>
        </div>
        <div>
          <p class="warranty-block__text">
            <span>{{ product.getWarrantyPlural() }}</span>
            {{ _T("@Product official warranty from") }}
          </p>
          <p class="warranty-block__subtext">
            {{ _T("@Product returns exchanges") }}
          </p>
        </div>
      </div>
    </div>
    <div class="delivery-block">
      <h4 class="delivery-block__title">{{ _T("@Delivery") }}</h4>
      <DeliveryItem
        v-for="(delivery, index) in getDeliveries(product)"
        :key="index"
        :delivery="delivery"
      />
    </div>
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import DeliveryItem from "~/modules/product/ui/pages/Summary/DeliveryItem.vue";

const props = defineProps({
  product: { type: Product, required: true },
});

const getDeliveries = (product) => [
  {
    title: "@Pickup from branch",
    icon: "nova-poshta-icon.svg",
    terms: {
      text: isFreeDelivery(product) ? "@Free shipping" : "@Paid",
      color: isFreeDelivery(product) ? "#159822" : "#2B2B2B",
    },
    isFreeDelivery: isFreeDelivery(product),
  },
  {
    title: "@Address delivery",
    icon: "nova-poshta-icon.svg",
    terms: {
      text: "@Paid",
      color: "#2B2B2B",
    },
    isFreeDelivery: false,
  },
];

const { extendSchema } = inject("productSchema");

const getShippingDetailsSchema = (product) => {
  return {
    shippingDetails: getDeliveries(product).map((delivery) => {
      return {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          minValue: delivery.isFreeDelivery ? 0 : 70,
          currency: "UAH",
        },
        deliveryTime: {
          "@type": "ShippingDeliveryTime",
          transitTime: {
            "@type": "QuantitativeValue",
            minValue: 1,
            maxValue: 3,
            unitCode: "DAY",
          },
          handlingTime: {
            minValue: 1,
            maxValue: 3,
            unitCode: "DAY",
          },
        },
      };
    }),
  };
};

nextTick(() => {
  extendSchema("offers", getShippingDetailsSchema(props.product));
});
</script>

<style scoped lang="scss">
.summary-delivery {
  @include flex-container(column, flex-start);
  gap: 24px;

  border-bottom: 1px solid var(--color-sky-base);
  border-top: 1px solid var(--color-sky-base);

  padding-block: 24px;

  @include mobile {
    gap: 16px;
    padding-block: 16px;
  }
}

.warranty-block {
  &__content {
    @include flex-container(row, flex-start, center);
    gap: 16px;
  }

  &__icon-w {
    position: relative;
  }

  &__icon {
    @include fixedHW(40px);

    @include mobile {
      @include fixedHW(32px);
    }
  }

  &__icon-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include font(16, 24, 700);
    color: white;

    @include mobile {
      @include font(12, 16, 700);
    }
  }

  &__text {
    @include font(18, 26);

    @include mobile {
      @include font(16, 24);

      margin-bottom: 4px;
    }

    & span {
      color: var(--color-primary-base);
      font-weight: 600;
    }
  }

  &__subtext {
    @include font(14, 20);

    @include mobile {
      @include font(12, 16);
    }
  }
}

.warranty-block {
  padding-bottom: 24px;

  border-bottom: 1px solid var(--color-sky-base);

  @include mobile {
    border-bottom: none;
    padding-bottom: 0;
  }
}

.delivery-block,
.warranty-block {
  @include flex-container(column, flex-start);
  gap: 8px;

  &__title {
    @include font(18, 20, 700);

    @include mobile {
      font-size: 16px;
    }
  }
}
</style>
