<template>
  <!--  <RenderCacheable-->
  <!--    :max-age="86400"-->
  <!--    :cache-key="[getUserLanguage.name, productHash].join('&#45;&#45;')"-->
  <!--    :async-data-keys="['configurations', 'reviews']"-->
  <!--  >-->
  <SummaryWrapper
    :product="product"
    :navigation-height="navigationHeight"
    :configurations="configurations"
    :reviews="reviews"
  />
  <!--  </RenderCacheable>-->
</template>

<script setup>
import { Product } from "~/models/product.model";
import SummaryWrapper from "~/modules/product/ui/pages/Summary/SummaryWrapper.vue";
import { useLanguageFilterStore } from "~/store/languageFilterStore";
import { useAsyncAPI } from "~/uses/useMyFetch";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: false },
  productHash: { type: [String, null], required: true },
});

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const configurations = ref(null);
const reviews = ref(null);

const patternSeo = {
  useCustomMeta: true,
  metaTitle: {
    postfix: () => _T("@Buy"),
  },
  metaDescription: {
    postfix: () =>
      _T(
        "@buy at a competitive price from the manufacturer, delivery, warranty",
      ),
  },
  metaKeywords: {},
};

const getConfigurations = () =>
  useAsyncAPI("/catalog/product/configurations", {
    key: "configurations",
    params: {
      productId: props.product.id,
    },
  }).then(({ data }) => {
    configurations.value = data;
  });

const getReviews = () =>
  useAsyncAPI("/catalog/product/review/list", {
    key: "reviews",
    params: {
      pageNum: 1,
      pageSize: 3,
      productId: props.product.id,
    },
  }).then(({ data }) => {
    reviews.value = data;
  });

const getPromises = () => [
  new Promise((resolve) => resolve(getReviews())),
  new Promise((resolve) => resolve(getConfigurations())),
];

const fetchAll = () => Promise.all(getPromises());

watch(
  () => props.product,
  () => {
    configureProductSeo(props.product, patternSeo);
    fetchAll();
  },
  { immediate: true },
);
</script>
