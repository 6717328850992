<template>
  <div class="summary">
    <div class="summary__container">
      <div class="summary__card">
        <PageHeader v-if="isMobile" :product="product" />
        <SummarySwiper
          :product="product"
          :navigation-height="navigationHeight"
        />
        <div class="summary__right-side">
          <PageHeader v-if="!isMobile" :product="product" />
          <Configuration :product="product" :configurations="configurations" />
          <SummaryPromotion
            v-if="null !== getActivePromotion(product) && product.isOrderable"
            :category="getActivePromotion(product)"
          />
          <Purchase :product="product" />
          <template v-if="product.isOrderable">
            <CreditPayment :product="product" />
            <DeliveryBlock :product="product" />
            <PaymentMethods />
          </template>
        </div>
      </div>
      <SimilarProductList :product="product" :limit="12" />
    </div>
    <Description :product="product" />
    <SummaryReviews :product="product" :reviews="reviews" />
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import Purchase from "~/modules/product/ui/components/Purchase.vue";
import DeliveryBlock from "~/modules/product/ui/pages/Summary/DeliveryBlock.vue";
import PaymentMethods from "~/modules/product/ui/pages/Summary/PaymentMethods.vue";
import Description from "~/modules/product/ui/pages/Summary/Description.vue";
import SummaryReviews from "~/modules/product/ui/pages/Summary/SummaryReviews.vue";
import SummarySwiper from "~/modules/product/ui/pages/Summary/SummarySwiper.vue";
import CreditPayment from "~/modules/product/ui/pages/Summary/CreditPayment.vue";
import Configuration from "~/modules/product/ui/pages/Summary/Configuration.vue";
import SimilarProductList from "~/modules/shared/components/sliders/similarProductList/SimilarProductList.vue";
import { addScopedEventListener } from "~/utils/eventListener";
import { useCategoriesStore } from "~/store/categoriesStore";
import { promotionCategoryCode } from "~/config/category.config";
import SummaryPromotion from "~/modules/product/ui/pages/Summary/SummaryPromotion.vue";
import PageHeader from "~/modules/product/ui/components/PageHeader.vue";

const props = defineProps({
  product: { type: Product, required: true },
  configurations: { type: [Array, null], required: false },
  navigationHeight: { type: Number, required: false },
  reviews: { type: [Object, null], required: true },
});

onBeforeMount(() => {
  const debounced = useDebounceFn(() => {
    getMobile();
  }, 100);

  debounced();

  addScopedEventListener(window, "resize", debounced);
});

const { width: windowSize } = useWindowSize();
const isMobile = ref(false);

const categoriesStore = useCategoriesStore();
const { getCategoryById } = storeToRefs(categoriesStore);

const getMobile = () => {
  isMobile.value = windowSize.value <= 680;
};

const getActivePromotion = (product) => {
  let promotion = null;

  product.categories.forEach(({ id }) => {
    const currentCategory = getCategoryById.value(id);

    if (
      promotionCategoryCode === currentCategory.parent?.code &&
      !isPromotionEnded(currentCategory)
    ) {
      promotion = currentCategory;
    }
  });

  return promotion;
};
</script>
